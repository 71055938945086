import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import SrOnly from "../utils/SrOnly"
import { CardBase } from "./card"

const BreadcrumbNav = styled(CardBase)`
  margin-bottom: 16px;
  padding: 10px 15px;
}
`

const BreadcrumbList = styled.ol`
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
`
const BreadcrumbListItem = styled.li`
  display: inline;
  padding: 0;
  margin: 0;

  &:before {
    content: " » ";
  }

  &:first-child:before {
    display: none;
  }
`

export const BreadcrumbItem = ({ to, children }) => {
  return (
    <BreadcrumbListItem>
      {to ? <Link to={to}>{children}</Link> : children}
    </BreadcrumbListItem>
  )
}
BreadcrumbItem.propTypes = {
  to: PropTypes.any,
  children: PropTypes.node,
}

const Breadcrumbs = ({ children }) => {
  return (
    <BreadcrumbNav as="nav" id="breadcrumb" role="navigation">
      <SrOnly as="h1" id="breadcrumb-label">
        Breadcrumbs
      </SrOnly>
      <BreadcrumbList aria-labelledby="breadcrumb-label">
        <BreadcrumbItem to="/">Home</BreadcrumbItem>
        {children}
      </BreadcrumbList>
    </BreadcrumbNav>
  )
}
Breadcrumbs.propTypes = {
  children: PropTypes.any,
}

export default Breadcrumbs
