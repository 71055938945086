import PropTypes from "prop-types"

export const articleTypePropType = PropTypes.shape({
  label: PropTypes.shape({
    single: PropTypes.string.isRequired,
    plural: PropTypes.string.isRequired,
  }).isRequired,
}).isRequired

export const group = {
  label: {
    single: "Group",
    plural: "Groups",
  },
}

export const convention = {
  label: {
    single: "Convention",
    plural: "Conventions",
  },
}

export const store = {
  label: {
    single: "Store",
    plural: "Stores",
  },
}

export const japaneseEvent = {
  label: {
    single: "Japanese events",
    plural: "Japanese event",
  },
}

export const blog = {
  label: {
    single: "Blog",
    plural: "Posts",
  },
}

export const page = {
  label: {
    single: "Page",
    plural: "Pages",
  },
}

export default {
  group,
  convention,
  store,
  japaneseEvent,
}
