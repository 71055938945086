import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import ArticlePage from "./abstract-article"
import { convention } from "../articleTypes"

const ConventionPage = ({ data, ...props }) => {
  return (
    <ArticlePage
      {...props}
      article={data.convention}
      articleType={convention}
      articleTypeTag="convention"
      articleTypePath="/conventions"
    />
  )
}
ConventionPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
}

export const query = graphql`
  query($slug: String!) {
    convention: markdownRemark(
      fields: { contentKind: { eq: "convention" }, slug: { eq: $slug } }
    ) {
      ...TinaRemark
      ...ArticlePageFragment
    }
  }
`

export default ConventionPage
