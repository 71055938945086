import { relative } from "path"
import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { usePlugin } from "tinacms"
import { useRemarkForm } from "gatsby-tinacms-remark"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumbs, { BreadcrumbItem } from "../components/breadcrumbs"
import Entry, {
  EntryImg,
  EntryImgFloat,
  EntryImgHeader,
  EntryContent,
} from "../components/entry"
import { articleTypePropType } from "../articleTypes"

const ArticlePage = ({
  article: sourceArticle,
  articleType: { label },
  articleTypeTag,
  articleTypePath,
  entryMeta,
  footer,
  pageContext,
}) => {
  const { slug } = pageContext
  const [article] = useArticleForm(sourceArticle, {
    label: `${label.single} (${slug})`,
    title: {
      description: `Name of the ${label.single}`,
    },
  })
  const {
    frontmatter: { title, thumb, images },
    html,
    excerpt,
  } = article

  const floatRight =
    !images ||
    images.length > 1 ||
    !images.every(image => image.childImageSharp.fluid.aspectRatio > 4 / 1)

  const ImgContainer = floatRight ? EntryImgFloat : EntryImgHeader

  return (
    <Layout>
      <SEO
        title={title}
        description={excerpt}
        meta={{
          og: {
            type: "article",
            section: label.plural,
            tag: [articleTypeTag],
          },
        }}
        image={thumb}
      />

      <Breadcrumbs>
        {articleTypePath && (
          <BreadcrumbItem to={articleTypePath + "/"}>
            {label.plural}
          </BreadcrumbItem>
        )}
        <BreadcrumbItem>{title}</BreadcrumbItem>
      </Breadcrumbs>

      <Entry
        id="content"
        role="main"
        title={title}
        entryMeta={entryMeta}
        footer={footer}
      >
        {images?.length > 0 && (
          <ImgContainer>
            {images.map(image => (
              <EntryImg
                key={image.id}
                fixed={
                  image && image.childImageSharp && image.childImageSharp.fixed
                }
                fluid={
                  image && image.childImageSharp && image.childImageSharp.fluid
                }
              />
            ))}
          </ImgContainer>
        )}
        <EntryContent dangerouslySetInnerHTML={{ __html: html }} />
      </Entry>
    </Layout>
  )
}
ArticlePage.propTypes = {
  article: PropTypes.any,
  articleType: articleTypePropType,
  articleTypeLabel: PropTypes.string,
  articleTypeTag: PropTypes.string,
  articleTypePath: PropTypes.string,
  entryMeta: PropTypes.any,
  footer: PropTypes.any,
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
}

export const ArticlePageFragment = graphql`
  fragment ArticlePageFragment on MarkdownRemark {
    id
    fields {
      slug
    }
    frontmatter {
      title
      imageFit: image_fit
      thumb: image {
        ... on File {
          ...SeoImageThumbnailFragment
        }
      }
      images: page_images {
        ... on File {
          id
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    html
    excerpt(format: PLAIN)
  }
`

export function useArticleForm(sourceData, { label, title, extraMetadata }) {
  const [data, form] = useRemarkForm(sourceData, {
    label,
    fields: [
      {
        label: "Title",
        name: "frontmatter.title",
        component: "text",
        ...title,
      },
      {
        label: "Featured Image",
        name: "rawFrontmatter.image",
        description: "Image displayed in the groups list, should be a logo",
        component: "image",
        previewSrc: (src, path, formValues) =>
          formValues.frontmatter.thumb?.childImageSharp.fixed.src ?? "",
        uploadDir: () => "/content/assets/",
        parse: media => (media ? relative("/content/type", media.id) : ""),
      },
      {
        label: "Featured Image (fit)",
        name: "rawFrontmatter.image_fit",
        component: "select",
        options: [
          { value: "contain", label: "Contain" },
          { value: "cover", label: "Cover" },
        ],
        parse: value => (value === "contain" ? undefined : "cover"),
        format: value => value ?? "contain",
      },
      ...(extraMetadata ?? []),
      {
        label: "Content",
        name: "rawMarkdownBody",
        component: "markdown",
      },
    ],
  })
  usePlugin(form)

  return [data, form]
}

export default ArticlePage
